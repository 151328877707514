<template>
  <div>
    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#sortFilter" @click="openFilters"
      class="fltr-srt-cls">{{ $t('Sort/Filter') }}</a>
    <div class="modal fade custmization-modal orders-tab-main sortfiler-main" id="sortFilter" tabindex="-1"
      aria-labelledby="sortFilterLabel" aria-hidden="true" ref="filterModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header align-items-center">
            <h4 class="modal-title" id="filterLabel">{{ $t('Sort/Filter') }}</h4>
            <a data-bs-dismiss="modal" class="lnk" @click="clearFilter()">{{ $t('Clear All') }}</a>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs border-0 mb-3">
              <li class="nav-item">
                <a href="#sort" class="nav-link navbar-default active " data-bs-toggle="tab">{{ $t('Sort') }}</a>
              </li>
              <li class="nav-item">
                <a href="#cusines" class="nav-link navbar-default" data-bs-toggle="tab">{{
                  $auth.setting.cuisine_name }}</a>
              </li>
            </ul>
            <form action="javascript:void(0)">
              <div class="tab-content sort-filter-nw">
                <div id="sort" class="tab-pane active tab-box">
                  <div class="customize-type mb-4">
                    <div class="form__group">
                      <div class="form__radio-group">
                        <input type="radio" v-model="sortType" name="size" id="classic1" class="form__radio-input"
                          @click="e => updateFilterSort(e.target.value)" :checked="filters.sort == '0'" value="0" />
                        <label class="form__label-radio" for="classic1">
                          <span class="form__radio-button"></span>{{ $t('Relevance') }}
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input type="radio" v-model="sortType" name="size" id="classic2" class="form__radio-input"
                          @click="e => updateFilterSort(e.target.value)" :checked="filters.sort == '1'" value="1" />
                        <label class="form__label-radio" for="classic2">
                          <span class="form__radio-button"></span>{{ $t('Cost for two') }}
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input type="radio" v-model="sortType" name="sortType" id="classic3" class="form__radio-input"
                          @click="e => updateFilterSort(e.target.value)" :checked="filters.sort == '2'" value="2" />
                        <label class="form__label-radio" for="classic3">
                          <span class="form__radio-button"></span>{{ $t('Delivery Time') }}
                        </label>
                      </div>
                      <div class="form__radio-group">
                        <input type="radio" v-model="sortType" name="sortType" id="classic4" class="form__radio-input"
                          @click="e => updateFilterSort(e.target.value)" :checked="filters.sort == '3'" value="3" />
                        <label class="form__label-radio" for="classic4">
                          <span class="form__radio-button"></span>{{ $t('Rating') }}
                        </label>
                      </div>
                    </div>
                    <!-- </form> -->
                  </div>
                </div>
                <div id="cusines" class="tab-pane tab-box">
                  <div class="customize-type custom-checkbox">
                    <div class="form-group" v-for="(cuisine, index) in cuisines" :key="index">
                      <div>
                        <input type="checkbox" v-model="selected" :value="cuisine.cuisine_id"
                          :id="cuisine.cuisine_id" />
                        <label :for="cuisine.cuisine_id">{{ cuisine.cuisine_name }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer full-btn d-block">
            <button @click="setFilterData()">{{ $t('Apply') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
  data() {
    return {
      filter: false,
      cusinesIDs: [],
      sortType: '0',
      selected: [],
    }
  },

  computed: {
    ...mapGetters("restaurant", ["cuisines", "filters"])
  },

  mounted() {
    //this.getCuisine();    
  },

  methods: {
    ...mapActions("restaurant", ["getCuisines", "updateFilterSort"]),
    ...mapMutations("restaurant", ["updateSortCuisines"]),

    openFilters() {
      let modal1 = bootstrap.Modal.getOrCreateInstance('#sortFilter');

      window.$(this.$refs.filterModal).modal();
      window.$("#sortFilter").on('show.bs.modal', function () { });
      window.$('#sortFilter').modal('toggle');
      this.getCuisine();
      this.sortType = this.filters.sort;
      this.selected = this.filters.cuisine_id;

      // window.$("#AlertModalPlaced").on('show.bs.modal',function(){});
      // window.$("#AlertModalPlaced").modal("show");
      // window.$(this.$refs.AlertModal).modal();
      // $(this.$refs.AlertModal).on("hidden.bs.modal", this.oncloseModle);
    },

    getCuisine() {
      this.getCuisines({ vendor_id: this.$auth.getVendorId(), is_langauge: this.$store.state.lang.locale })
    },

    setFilterData() {
      let getFilter = { "sort": this.sortType, 'cusinesIds': this.selected };
      this.$store.commit("restaurant/updateSortCuisines", getFilter);
      this.$emit("getFilterData", getFilter);
      window.$('#sortFilter').modal('toggle');
    },

    clearFilter() {
      this.cusinesIDs = '';
      this.sortType = '0';
      this.selected = [];
      let getFilter = { "sort": this.sortType, 'cusinesIds': this.selected };
      this.$store.commit("restaurant/updateSortCuisines", getFilter);
      this.$emit("getFilterData", getFilter);
      window.$('#sortFilter').modal('toggle');
    },

  }
};
</script>